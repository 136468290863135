.side-ad-picture {
    width: 100%;
    height: 360px;
    margin-top: 6%;
}

.bottom-ad-picture {
    width: 410px;
    height: 350px;
    margin-left: 2%;
}

.product-grid-main-pic {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.grid-element-vezi-detalii {
    width: 80%;
    margin-left: 10%;
    // margin-top: 5%;
}

.brand-images {
    max-height: 25px;
}

#first-bottom-ad {
    margin-left: 0;
}

#first-side-ad {
    margin-top: 0;
}
