$backgroundGray: #F6F6F6;
$hintGray: #C8C8C8;
$searchGradientDark: #2A3588;
$searchGradientLight: #1A73BA;
$subSubCategoryGray: #828282;
$productBottomPartGray: #ECECEC;
$productNameGray: #414042;
$productPathGray: #555555;
$white: #fff;
$red: rgb(193, 39, 45)
