@mixin ad-bar-body {
  @include box();
  border-radius: 5px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &__box {
    position: absolute;
    bottom: 1rem;
    left: -.5rem;
    background-color: $productBottomPartGray;
    border-radius: 0 2rem 2rem 0;
    padding: .5rem 1.5rem;
    min-height: 80px;
    &--title {
      @include sub-title();
      color: $searchGradientDark;
    }
    &--link {
      cursor: pointer;
      color: $searchGradientDark;
      @include normal()
    }
    z-index: 1;
  }
  &__shadow {
    position: absolute;
    height: 0;
    width: 0;
    left: -1rem;
    bottom: 0.5rem;
    border: 0.5rem solid transparent;
    border-right-color: $searchGradientDark;
    z-index: 0;
  }
}

.side-ad-bar {
  margin-bottom: .5rem;
  min-height: 200px;
  max-height: 350px;
  flex: 1;
  &:last-child {
    margin-bottom: 0px;
  }
  @include ad-bar-body();
  &__box {
    max-width: 200px;
  }
}

.ad-bar {
  min-height: 200px;
  width: calc(33.3% - .5rem);
  margin: 0 .5rem;
  @include tablet() {
    width: calc(100% - .5rem);
    margin-bottom: .5rem;
  }
  @include ad-bar-body();
  &__box {
    min-width: 200px;
    max-width: 350px;
    @include tablet() {
      max-width: 200px;
    }
  }
}
