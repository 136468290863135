.home-page {
  @include container-margin();
  &__navigation-section {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }
  &__promotions-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    &--products-area {
      margin-left: .5rem;
      flex: 1;
      @include tablet() {
        margin-left: 0;
      }
    }
  }
}
