.tree-ads-section {
  margin-top: 2rem;
  display: block;
  &__title {
    @include sub-title();
    margin-top: 1rem;
    margin-bottom: .5rem;
  }
  &__container {
    display: flex;
    flex-direction: row;
    left: -.5rem;
    width: calc(100% + 1rem);
    position: relative;
    @include tablet() {
      flex-direction: column;
    }
  }
  @include tablet() {
    margin-top: 0;
  }
}
