.top-nav-bar {
  &__top {
    display: flex;
    @include container-padding();
    margin-top: 2rem;
    margin-bottom: .5rem;
    justify-content: space-between;
    align-items: center;
    & .top-navigation-icon {
      width: 300px;
      height: auto;
    }
    &--right-nav-elements {
      @include desktop {
        display: none;
      }
      &--component {
        margin-left: 1rem;
        @include normal();
        color: $productNameGray;
      }
    }
  }
  &__bottom {
    background-image: linear-gradient(to right, $searchGradientDark, $searchGradientLight, $searchGradientDark);
    @include container-padding();
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .menu-container {
      @include container-margin();
      position: absolute;
      display: flex;
      left: 0;
      right: 0;
      top: calc(100% + .5rem);
      min-height: 200px;
      z-index: $menuElements;
    }
    &--right-nav-elements {
      display: flex;
      align-items: center;
      @include tablet() {
        display: none;
      }
      &--component {
        @include normal();
        color: $white;
        margin-left: 1rem;
        &.fb-image {
          width: 2rem;
        }
      }
    }
  }
}
