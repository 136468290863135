.category-list {
  @include box();
  border-radius: 5px;
  flex: 1;
  min-height: 200px;
  margin-left: .5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  &__section {
    width: 250px;
    padding-right: 2rem;
    &--title {
      font-size: 18px;
      font-weight: 400;
      font-family: 'Lato', sans-serif;
      padding: 9px 0;
      display: inline-block;
      color: $searchGradientDark;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &--sub-list {
      .sub-category-element {
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        display: block;
        padding: 0;
        color: $productNameGray;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $searchGradientDark;
          text-decoration: underline;
        }
      }
    }
  }
}
