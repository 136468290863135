.mobile-menu {
  @include box();
  @include container-margin();
  border-radius: 5px;
  position: absolute;
  top: calc(100% + .5rem);
  bottom: .5rem;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1rem;
  height: max-content;
  max-height: calc(100vh - 11rem);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  &__top-nav {
    margin: 1rem 0;
    &--container {
      display: flex;
      flex-wrap: wrap;
    }
    &--element-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .5rem 0;
      &--logo {
        width: 20px;
        .category-logo-image {
          width: 100%;
          height: auto;
        }
      }
      &--title {
        @include sub-title();
        color: $productNameGray;
        flex: 1;
        padding-left: 1rem;
      }
    }
    &--element-content {
      padding: .5rem 0;
      display: inline-block;
      width: 49%;
      @include mobile() {
        width: 100%;
      }
      &--title {
        @include normal();
        font-weight: bold;
        color: $searchGradientDark;
        text-decoration: none;
      }
      &--elements {
        padding: .5rem 0;
        @include normal();
        color: $productNameGray;
        font-weight: 300;
        &--link {
          padding: .25rem 0;
          cursor: pointer;
        }
      }
    }
  }
  &__bottom-nav {
    margin: 1rem 0;
    border-top: 1px solid $subSubCategoryGray;
    text-align: center;
    &--elements {
      @include normal();
      padding: .5rem;
      display: block;
      color: $productNameGray;
      &.image {
        margin: 0 auto;
        width: 60px;
      }
    }
  }
}
