.department-contact-element {
  flex: 1;
  min-width: 200px;
  @include normal();
  color: $productNameGray;
  border-right: solid 1px $hintGray;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem 0 .5rem 0;
  &:first-child {
    border-left: solid 1px $hintGray;
  }
  @include largeDesktop {
    border: none;
    &:first-child {
      border-left: none;
    }
  }
  &__icon {
    height: 50px;
  }
  &__emails {
    .email {
      color: $productNameGray;
      text-decoration: none;
      display: block;
      padding-top: .5rem;
    }
  }
  &__phone-numbers {
    .phone-number {
      color: $productNameGray;
      text-decoration: none;
      display: block;
      padding-top: .5rem;
    }
  }
}
