.jobs-page {
  &__section {
    margin: 1rem 0 1rem 0;
    padding: .5rem;
    @include container-margin();
    &.top {
      background-color: $backgroundGray;
      border-radius: 5px;
    }
    &--title {
      @include sub-title();
      color: $searchGradientDark;
      margin: 1rem 0;
      font-weight: bold;
    }
    &--text {
      @include normal();
      color: $searchGradientDark;
    }
  }
  &__image-section {
    @include container-margin();
    position: relative;
    &--image {
      width: 100%;
      height: auto;
    }
    &--button {
      position: absolute;
      @include normal();
      color: $white;
      padding: .5rem;
      cursor: pointer;
      border: none;
      background-image: linear-gradient(to right, $searchGradientDark, $searchGradientLight, $searchGradientDark);
      border-radius: 5px;
      bottom: -1rem;
      right: 1rem;
      &:focus {
        outline: none;
      }
    }
  }
}
