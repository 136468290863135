.image-viewer-bottom {
  flex: 1;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: center;
  &.overflowing {
    justify-content: left;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  &__element {
    cursor: pointer;
    max-width: 200px;
    min-width: 100px;
    margin-right: .5rem;
    min-height: 100px;
  }
}
