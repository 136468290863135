.grid-element {
  @include box;
  background: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: calc(25% - .5rem);
  margin: 1rem .25rem 0rem .25rem;
  min-height: 225px;
  background-color: $backgroundGray;
  cursor: pointer;
  @include desktop() {
    width: calc(33.3% - .5rem);
  }
  @include tablet() {
    width: calc(50% - .5rem);
  }
  @include mobile() {
    width: calc(100% - .5rem);
  }
  &__top {
    width: 100%;
    padding: .5rem;
    display: flex;
    min-height: 250px;
    max-height: 300px;
    overflow: hidden;
    background-color: $white;
    border-radius: 5px 5px 0 0;
    &--image {
      margin: auto;
      width: 100%;
      height: auto;
    }
  }
  &__bottom {
    flex: 1;
    text-align: center;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &--label {
      @include small();
      color: $subSubCategoryGray;
      margin: .5rem 0 .5rem 0;
    }
    &--product-link {
      position: relative;
      display: flex;
      cursor: pointer;
      .product-link-icon {
        margin: auto;
        width: 100%;
      }
      .product-link-text {
        margin: auto;
        position: absolute;
        @include small();
        color: $white;
        text-decoration: none;
        top: calc(50% - .5rem);
        left: 25%;
      }
    }
  }
}
