.search-bar {
  width: 50%;
  border-radius: 1rem;
  background-color: $white;
  height: 2rem;
  padding: .25rem 1rem .25rem 1rem;
  display: flex;
  flex-direction: row;
  @include tablet {
    width: 100%;
  }
  &__input {
    width: 100%;
    display: block;
    height: 1.5rem;
    border: none;
    @include normal();
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $hintGray;
    }
  }
  &__magnifier-button {
    background-color: white;
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &--image {
      width: auto;
      height: 100%;
    }
  }
}
