.product-page {
  &__top-section {
    @include container-margin();
    margin-top: .5rem;
    &--title {
      @include title;
      color: $searchGradientDark;
      margin-bottom: 1rem;
    }
    &--data {
      display: block;
      @include tablet {
        flex-direction: column;
      }
      & .data-left {
        display: inline-block;
        width: 50%;
        padding-right: 1rem;
        vertical-align: top;
        @include tablet {
          width: 100%;
          padding-right: 0;
        }
      }
      & .data-right {
        display: inline-block;
        width: 50%;
        padding-left: 1rem;
        vertical-align: top;
        @include tablet {
          width: 100%;
          padding-left: 0;
        }
        &__brand {
          width: 50%;
          &--image {
            width: 100%;
            height: auto;
          }
        }
        &__review {
          @include normal();
          color: $productNameGray;
          p {
            margin: 1rem 0;
          }
          b {
            font-size: 1.1rem;
          }
        }
        &__possibilities {
          margin-top: 2rem;
          &--title {
            @include normal();
            color: $productNameGray;
            font-size: 1.1rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }
          &--list {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            left: -.1rem;
            &--element {
              @include box();
              background-color: $white;
              border-radius: 5px;
              padding: .25rem;
              @include small();
              color: $productNameGray;
              margin: .1rem;
            }
          }
        }
      }
    }
  }
  &__bottom-section {
    @include container-margin();
  }
}
