.category-bar {
  @include box();
  border-radius: 5px;
  padding: 1.3125rem 1rem;
  width: 200px;
  min-height: 200px;
  &__item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: .625rem 0 .625rem 0;
    position: relative;
    &--logo {
      width: 20px;
      height: 24px;
      position: relative;
      &--image {
        margin-bottom: 0px;
        width: 20px;
        max-height: 24px;
        height: auto;
        position: absolute;
        left: 0px;
        bottom: 0px;
      }
    }
    &--title {
      padding-left: 1rem;
      position: absolute;
      bottom: 6.8px;
      left: 20px;
      @include normal();
    }
  }
}
