* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Screen break points
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

// Font constants
$baseFontFamily: 'Lato', sans-serif;
$baseFontSize: 16px;

// Z-indexes
$menuElements: 1000;
$cookiePopup: 1001;
