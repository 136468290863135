.footer {
  &__contact-details {
    @include container-padding();
    @include box();
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    @include desktop {
      flex-direction: column;
    }
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
    &--element {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;

      &.contact-holder {
        flex-direction: row;
        @include mobile {
          flex-direction: column;
          white-space: normal;
          word-break: break-all;
        }
      }

      @include desktop {
        align-items: center;
        padding-bottom: 2rem;
      }
      .contact-detail {
        display: flex;
        align-items: center;
        @include container-padding();
        @include normal();
        padding-top: .5rem;
        padding-bottom: .5rem;
        &:first-child {
          color: $searchGradientDark;
          @include title();
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        &__icon {
          width: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 0.5rem;
        }
        &__text {
          color: $productNameGray;
          text-decoration: none;
          @include desktop { 
            margin: .5rem 0 .5rem 0;
            word-break: break-word;
          }
        }
      }
    }
  }
  &__departments {
    @include box();
    @include container-padding();
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include desktop { 
      align-items: center;
    }
  }
  &__links {
    margin-top: 2rem;
    background-image: linear-gradient(to right, $searchGradientDark, $searchGradientLight, $searchGradientDark);
    @include container-padding();
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-align: center;
    @include mobile() {
      padding-bottom: 1rem;
    }
    &--element {
      color: $white;
      @include normal();
      margin-left: 2rem;
      @include mobile() {
        display: block;
        padding: .5rem 0 .5rem 0;
      }
    }
  }
  &__bottom {
    @include normal;
    text-align: center;
    padding: 1rem 0 1rem 0;
    color: $productNameGray;
  }
}
