.image-presenter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100px;
  .hidden {
    position: absolute;
    opacity: 0;
  }
}
