@mixin small {
  font-family: $baseFontFamily;
  font-size: .8rem;
  font-weight: 400;
}

@mixin normal {
  font-family: $baseFontFamily;
  font-size: 1rem;
  font-weight: 400;
}

@mixin sub-title {
  font-family: $baseFontFamily;
  font-size: 1.2rem;
  font-weight: 400;
}

@mixin title {
  font-family: $baseFontFamily;
  font-size: 1.5rem;
  font-weight: 400;
}

html {
  font-size: $baseFontSize;
  @include mobile {
    font-size: $baseFontSize * 0.9;
  }
}
