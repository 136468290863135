.product-slider {
  @include box();
  border-radius: 5px;
  flex: 1;
  min-height: 200px;
  overflow: hidden;
  margin-left: .5rem;
  position: relative;
  @include tablet() {
    margin-left: 0;
  }
  &__caroussel {
    height: 100%;
    &--element {
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      &--image {
        width: 100%;
        height: auto;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 5;
    &--element {
      width: 2rem;
      height: .5rem;
      border-radius: .25rem;
      background-color: $searchGradientDark;
      margin-left: 1rem;
      border: none;
      cursor: pointer;
      &.selected {
        background-color: $white;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

// Caroussel component overrides
.carousel.carousel-slider {
  height: 100%;
  .slider-wrapper {
    height: 100%;
    .slider {
      height: 100%;
    }
  }
}
