.category-label-container {
  position: relative;
  .category-label {
    @include normal();
    text-transform: uppercase;
    color: $white;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 1rem;
    min-width: 40px;
    padding: 5px;
    position: relative;
    @include tablet {
      white-space: initial;
    }
    &__hamb-menu-element {
      background-color: $white;
      width: 100%;
      height: 5px;
      border-radius: 3px;
      position: relative;
      margin: 3px 0;
      transform: rotate(0deg);
      transition: .2s ease-in-out;
      &.open:first-child {
        transform: rotate(45deg);
        top: 10px;
      }
      &.open:nth-child(2) {
        opacity: 0;
      }
      &.open:last-child {
        transform: rotate(-45deg);
        bottom: 6px;
      }
    }
  }
}

