.product-section-header {
  @include box();
  border-radius: 5px;
  padding: .5rem;
  margin: 2rem 0 2rem 0;
  width: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include tablet() {
    flex-direction: column;
  }
  &__title {
    text-transform: uppercase;
    @include normal();
    margin-right: 1rem;
    min-width: 200px;
    @include tablet() {
      margin-right: 0;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  &__list {
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    &--element {
      min-width: 200px;
      max-width: 400px;
      padding-left: .5rem;
      &--image {
        width: 100%;
        height: auto;
      }
    }
    &--scrollable-area {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scroll-behavior: smooth;

      // Hides the scrollbar
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;

      user-select: none;
    }
  }
}
