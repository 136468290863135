.product-image-viewer {
  @include box;
  background-color: $white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  &__big-image {
    max-width: 500px;
    cursor: zoom-in;
    &--preview {
      max-height: 250px;
    }
  }
}
