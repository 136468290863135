.left-ads-section {
  width: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
  @include tablet() {
    left: -.25rem;
    width: calc(100% + .5rem);
    margin-bottom: .5rem;

  }
}
