@mixin container-margin {
  margin-left: 10%;
  margin-right: 10%;
  @include desktop {
    margin-left: 5%;
    margin-right: 5%;
  }
  
  @include tablet {
    margin-left: 2%;
    margin-right: 2%;
  }
  
  @include mobile {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@mixin container-padding {
  padding-left: 10%;
  padding-right: 10%;
  @include desktop {
    padding-left: 5%;
    padding-right: 5%;
  }
  
  @include tablet {
    padding-left: 2%;
    padding-right: 2%;
  }
  
  @include mobile {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@mixin container-margin {
  margin-left: 10%;
  margin-right: 10%;
  @include desktop {
    margin-left: 5%;
    margin-right: 5%;
  }

  @include tablet {
    margin-left: 2%;
    margin-right: 2%;
  }

  @include mobile {
    margin-left: 2%;
    margin-right: 2%;
  }
}


@mixin box {
  border: 1px solid $hintGray;
  background-color: $backgroundGray;
}
