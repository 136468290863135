.product-details {
  margin-top: 2rem;
  &__options-bar {
    padding: .1rem;
    margin-bottom: 2rem;
    width: 100%;
    background-image: linear-gradient(to right, $searchGradientDark, $searchGradientLight, $searchGradientDark);
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include tablet {
      flex-direction: column;
    }
    &--option {
      padding: .5rem;
      background: none;
      border: none;
      border-radius: 5px;
      transition: background-color .2s;
      @include normal;
      cursor: pointer;
      color: $white;
      &:focus {
        outline: none;
      }
      &.selected {
        background-color: rgba($white, .2);
      }
    }
  }
  &__content {
    @include container-margin();
    display: flex;
    flex-direction: row;
    position: relative;
    @include tablet() {
      flex-direction: column;
    }
    &--text {
      flex: 1;
      @include normal();
      color: $productNameGray;
      &:not(:only-child) {
        margin-right: 1rem;
        @include tablet() {
          margin-right: 0;
        }
      }
    }
    &--image {
      flex: 1;
      text-align: center;
      .image {
        max-width: 100%;
        height: auto;
      }
      @include tablet() {
        margin-top: 1rem;
      }
      &:not(:only-child) {
        margin-left: 1rem;
        @include tablet() {
          margin-left: 0;
        }
      }
    }
    &--files {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      justify-content: center;
      width: calc(100% + 1rem);
      left: -.5rem;
      .file {
        @include box();
        border-radius: 5px;
        width: 200px;
        padding: .5rem;
        text-decoration: none;
        margin: .5rem;
        text-align: center;
        &__icon {
          width: 100px;
          height: auto;
        }
        &__text {
          @include normal();
          color: $productNameGray;
          font-weight: bold;
          padding: .5rem 0;
        }
      }
    }
  }
}
