// Media query mixins
@mixin mobile {
  @media screen and (max-width: $sm) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $md) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $lg) {
    @content;
  }
}

@mixin largeDesktop {
  @media screen and (max-width: $xl) {
    @content;
  }
}
