.product-grid {
  padding: .5rem;
  &__header {
    padding-bottom: .5rem;
    &--title {
      @include sub-title();
      text-decoration: none;
      color: $productNameGray;
    }
    &--description {
      @include normal();
      color: $subSubCategoryGray;
    }
  }
  &__list {
    float: left;
    position: relative;
    left: -.5rem;
    width: calc(100% + 1rem);
    display: flex;
    flex-wrap: wrap;
  }
}
