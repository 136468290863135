.romania-map {
  width: 100%;
  @include desktop {
    display: flex;
    justify-content: center;
  }
  &__layer {
    display: block;
    pointer-events: none;
    width: 100%;
    min-height: 300px;
    @include desktop {
      width: 320px;
    }
    &--county-holder {
      transform: scale(.4);
      @include desktop {
        transform: scale(.31);
      }
      .county {
        fill: none;
        stroke: $searchGradientDark;
        cursor: pointer;
        pointer-events: all;
        &:hover {
          fill: $searchGradientDark;
        }
        &.selected {
          fill: $searchGradientDark;
        }
      }
    }
  }
}
