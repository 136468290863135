.spinner {
  display: flex;
  width: 100px;
  margin: 0 auto;
  &__image {
    margin: auto;
    width: 100%;
    height: auto;
  }
}
