.expansion-panel {
  &__title {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    &--text {
      flex: 1;
    }
    &--icon {
      width: 30px;
      padding-top: 1rem;
      .icon {
        position: relative;
        height: 5px;
        border-radius: 2.5px;
        background-color: $subSubCategoryGray;
        width: 20px;
        transition: transform .2s;
        &.left {
          transform: rotate(-45deg) translate(7px, 9px);
        }
        &.right {
          transform: rotate(45deg) translate(-3px, -2px);
        }
      }
      &.open .icon {
        &.left {
          transform: rotate(45deg) translate(10px, -3px);
        }
        &.right {
          transform: rotate(-45deg) translate(-3px, -2px);
        }
      }
    }
  }
  &__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease;
    &.open {
      max-height: 5000px; // A value that is bigger then the body will ever get
    }
  }
}
