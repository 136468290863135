.cookiePopup {
  @include container-padding();

  position: fixed;
  bottom: -15px;
  z-index: $cookiePopup;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $searchGradientDark;

  &__title {
    @include title();
    color: $white;
    padding-bottom: 1rem;
  }
  &__desc {
    @include normal();
    color: $white;
    flex: 4;
  }
  &__accept {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @include mobile() {
      flex-direction: column;
    };
  }
  &__popupButton {
    @include sub-title();
    color: $white;
    text-transform: uppercase;
    background-color: $searchGradientLight;
    border: 1px solid $white;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}
