.about-us-page {
  &__section {
    margin: 1rem 0 1rem 0;
    padding: 1rem;
    @include container-margin();
    &.top {
      background-color: $backgroundGray;
      border-radius: 5px;
    }
    &--title-1 {
      @include sub-title();
      color: $searchGradientDark;
      margin: 1rem 0;
      font-weight: bold;
    }
    &--title-2 {
      @include sub-title();
      color: $red;
      margin: 1rem 0;
      font-weight: bold;
    }
    &--text {
      @include normal();
      color: $searchGradientDark;
      margin: 1rem 0;
    }
    &--regular-highlighted-text {
      @include small();
      color: $searchGradientDark;
      margin: 1rem 0;
      font-weight: bold;
    }
    &--regular-text {
      @include small();
      color: $productNameGray;
      margin: 1rem 0;
    }
  }
  &__image-section {
    @include container-margin();
    position: relative;
    &--image {
      width: 100%;
      height: auto;
    }
  }
  &__categories-section {
    background-color: $backgroundGray;
    margin: 1rem 0 1rem 0;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    // Hides the scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    &.overflowing {
      justify-content: left;
    }
    &--item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-left: solid $hintGray 1px;
      min-width: 150px;
      padding: 1rem;
      &:last-child {
        border-right: solid $hintGray 1px;
      }
      .image-holder {
        text-align: center;
        padding: 0.5rem;
        &__img {
          height: 40px;
          width: auto;
        }
      }
      .title {
        @include normal();
        color: $searchGradientDark;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
