.job-selector {
  &__selection-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 1rem 0;
    background-color: $backgroundGray;
    width: 100%;
    // Hides the scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    &.overflowing {
      justify-content: left;
    }
    &--element {
      padding: .5rem;
      margin: 0 .5rem;
      cursor: pointer;
      border-radius: 0;
      &.selected {
        background-color: $searchGradientDark;
        border-radius: 5px;
        .bar-element-title {
          color: $white;
        }
        .bar-element-logo {
          &__image {
            filter: invert(.5) brightness(5);
          }
        }
      }
      border-left: 1px solid $hintGray;
      &:last-child {
        border-right: 1px solid $hintGray;
      }
      .bar-element-title {
        @include small();
        margin: .5rem 0;
        text-align: center;
        color: $searchGradientDark;
      }
      .bar-element-logo {
        width: 100px;
        height: 100px;
        padding: 0 .5rem;
        text-align: center;
        &__image {
          height: 100%;
          width: auto;
        }
      }
    }
  }
  &__content-area {
    @include container-padding();
    color: $searchGradientDark;
    &--title {
      @include sub-title();
      color: $searchGradientDark;
      margin: 1rem 0;
      font-weight: bold;
      padding: 0 .5rem;
    }
    &--description {
      @include normal();
      padding: 0 .5rem;
      color: $searchGradientDark;
    }
  }
}
